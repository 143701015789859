<!--设备公共模块--警报页-->
<template>
  <div
    id="alarm"
    ref="alarm"
    v-loading="loading"
    :element-loading-text="$t('alarm.a0')"
  >
    <div class="header">
      <my-headertitle>{{ $t("alarm.a1") }}</my-headertitle>
      <a-form-model ref="ruleForm" :model="form" :rules="rules">
        <div class="form_row">
          <div class="form_col">
            <a-form-model-item :label="$t('alarm.a49')">
              <a-select
                v-model="showContents"
                style="width: 120px"
                @change="showContentsChange"
              >
                <a-select-option value="0">
                  <!--//未确认 未確認 -->
                  {{ $t("alarm.a35") }}
                </a-select-option>
                <a-select-option value="1">
                  <!--//未恢复 -->
                  {{ $t("alarm.a36") }}
                </a-select-option>
                <a-select-option value="2">
                  <!--//未应对 -->
                  {{ $t("alarm.a37") }}
                </a-select-option>
                <a-select-option value="3">
                  <!--//全部 -->
                  {{ $t("alarm.a38") }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </div>
          <div class="form_col">
            <a-form-model-item :label="$t('alarm.a2')">
              <a-input v-model="form.name" :placeholder="$t('alarm.a39')" />
            </a-form-model-item>
          </div>
          <!-- <div class="form_col">
            <a-form-model-item :label="$t('alarm.a3')">
              <a-select
                v-model="form.floor"
                style="width: 120px"
                placeholder="please select your zone"
              >
                <a-select-option
                  v-for="(tiem, value) in options"
                  :key="value"
                  >{{ tiem.name }}</a-select-option
                >
              </a-select>
            </a-form-model-item>
          </div> -->
          <div class="form_col">
            <a-form-model-item :label="$t('alarm.a4')" prop="date1">
              <a-date-picker
                v-model="form.date1"
                :disabled-date="disabledStartDate"
                show-time
                :allowClear="false"
                format="YYYY-MM-DD HH:mm:ss"
                @change="handleCalendarChange"
              />
            </a-form-model-item>
          </div>
          <div class="form_col">
            <a-form-model-item :label="$t('alarm.a5')" prop="date2">
              <a-date-picker
                v-model="form.date2"
                :disabled-date="disabledEndDate"
                show-time
                :allowClear="false"
                format="YYYY-MM-DD HH:mm:ss"
              />
            </a-form-model-item>
          </div>
          <div class="form_col">
            <a-form-model-item>
              <a-button type="primary" @click="inquirePage(1)">{{
                $t("energy.enedata006")
              }}</a-button>
              <!-- <a-button type="primary" @click="inquire">{{
                $t("energy.enedata006")
              }}</a-button> -->
            </a-form-model-item>
          </div>
           <div class="form_col">
            <a-form-model-item>
              <a-button
                type="primary"
                icon="file-text"
                ghost
                :disabled="data.length == 0"
                @click="csvExport"
                style="margin-right: 20px"
              >
                {{ $t("alarm.a51") }}
              </a-button>
            </a-form-model-item>
          </div>
        </div>
      </a-form-model>
    </div>
    <div class="main" ref="main">
      <!-- <my-tabletitle>{{ $t("alarm.a7") }}</my-tabletitle> -->
      <a-table
        :columns="columns"
        :dataSource="data"
        :scroll="size"
        :pagination="pagination"
        :rowClassName="fontClass"
        :rowKey="(record) => record.param"
      >
        <template slot="chaozuo" slot-scope="text, record">
          <div>
            <a-popover placement="left">
              <template slot="content">
                <a-button
                  type="primary"
                  class="table_but"
                  @click="acknowledgement(record)"
                  >{{ $t("alarm.a9") }}</a-button
                ><br />
                <a-button
                  type="primary"
                  class="table_but"
                  v-show="showManual(record)"
                  @click="manualRecovery(record)"
                  >{{ $t("alarm.a10") }}</a-button
                >
                <br v-show="showManual(record)" />
                <a-button
                  type="primary"
                  class="table_but"
                  @click="dispose(record)"
                  >{{ $t("alarm.a11") }}</a-button
                ><br />
                <a-button
                  type="primary"
                  class="table_but"
                  @click="() => annotation(record)"
                  >{{ $t("alarm.a14") }}</a-button
                >
              </template>
              <a>{{ $t("alarm.a15") }}</a>
            </a-popover>
          </div>
        </template>
      </a-table>
    </div>
    <a-modal
      :title="$t('alarm.a16')"
      centered
      :visible="visible"
      :closable="false"
      :getContainer="() => this.$refs.alarm"
      @cancel="annotationCancel"
    >
      <template slot="footer">
        <a-button key="back" @click="annotationCancel">
          {{ $t("alarm.a40") }}
        </a-button>
        <a-button
          key="submit"
          type="primary"
          :disabled="but_disabled"
          @click="annotationOk"
        >
          {{ $t("alarm.a41") }}
        </a-button>
      </template>
      <div v-loading="annotationLoading" :element-loading-text="$t('alarm.a0')">
        <a-textarea
          v-model="comment"
          :placeholder="$t('alarm.a17')"
          :auto-size="{ minRows: 10, maxRows: 10 }"
          ref="text"
        />
      </div>
    </a-modal>
    <a-modal
      :title="$t('alarm.a42')"
      centered
      :width="400"
      :visible="dispose_visible"
      :closable="false"
      :getContainer="() => this.$refs.alarm"
      @cancel="disposeCancel"
    >
      <template slot="footer">
        <a-button key="back" @click="disposeCancel">
          {{ $t("alarm.a40") }}
        </a-button>
        <a-button
          key="submit"
          type="primary"
          :disabled="but_disabled"
          @click="disposeOk"
        >
          {{ $t("alarm.a41") }}
        </a-button>
      </template>
      <div v-loading="processingLoading" :element-loading-text="$t('alarm.a0')">
        <div>
          <span>{{ `${$t("alarm.a43")}${correspondenceStatus}` }}</span>
          <span></span>
        </div>
        <br />
        <div>
          <span>{{ $t("alarm.a44") }}</span>
          <a-select v-model="processingStatus" style="width: 120px">
            <a-select-option value="1"> {{ $t("alarm.a37") }} </a-select-option>
            <a-select-option value="2"> {{ $t("alarm.a45") }} </a-select-option>
            <a-select-option value="3"> {{ $t("alarm.a46") }} </a-select-option>
          </a-select>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import tabletitle from "../../components/Title/tabletitle";
import headerTitle from "../../components/Title/headerTitle";
import { csvDownload } from "../../utils/utils";
import {
  getTenantList,
  addComment,
  acknowledgement,
  manualRecovery,
  processing,
} from "../../api/door";
import { getEnergyNotificationList,getNotificationPageList } from "../../api/energy";
import moment from "moment";

export default {
  name: "attendanceRecord",
  data() {
    return {
      visible: false,
      showContents: "0",
      notificationStatus: "",
      correspondenceStatus: "",
      // correspondenceStatusList:[
      //     {id:3,name:'出发'},
      //     {id:4,name:'到达'},
      //     {id:1,name:'处理完成'},
      //     {id:9,name:'上述之外'},
      // ],
      dispose_visible: false,
      form: {
        name: "",
        floor: 0,
        date1: moment().startOf("day"),
        date2: moment().endOf("day"),
      },
      size: { x: 2000, y: 240 },
      options: [],
      params: "",
      comment: "",
      but_disabled: false,
      row: undefined,
      data: [],
      loading: false,
      annotationLoading: false,
      startDate: undefined,
      correspondenceStatus: "",
      processingStatus: "1",
      processingLoading: false,
      rules: {
        date1: [
          {
            required: true,
            message: () => {
              return this.$t("alarm.a18");
            },
            trigger: "change",
          },
        ],
        date2: [
          {
            required: true,
            message: () => {
              return this.$t("alarm.a18");
            },
            trigger: "change",
          },
        ],
      },
      pagination: {
        total: 0, //数据总数
        pageSize: 30, //每页中显示30条数据
        showTotal: (total) => ` ${total} ${this.$t("alarm.a8")}`, //分页中显示总的数据
        showQuickJumper: true, //是否可以快速跳转至某页
        defaultCurrent: 1, //默认当前页面数
        hideOnSinglePage: false, //只有一页时是否隐藏分页：默认值false
        // 改变每页数量时更新显示
        onChange: (current, size) => {
          this.pagination.defaultCurrent = current;
          this.pagination.pageSize = size;
          this.inquirePage(current);
          console.log("onChange current", current);
        },
      },
      columns: [
        {
          title: "NO",          
          dataIndex: "NO",
          customRender: (text, record, index) =>
            `${
              (this.pagination.defaultCurrent - 1) * this.pagination.pageSize +
              index +
              1
            }`,
          width: 60,
        },
        {
          title: this.$t("alarm.a20"),          
          dataIndex: "date",
          customRender: (text, record, index) => {
            if (record.date == null) {
              return "--";
            } else {
              return record.date;
            }
          },
          width: 170,
          ellipsis: true,
        },
        {
          title: this.$t("alarm.a21"),          
          dataIndex: "normalTime",
          customRender: (text, record, index) => {
            if (record.normalTime == null) {
              return "--";
            } else {
              return record.normalTime;
            }
          },
          width: 170,
          ellipsis: true,
        },
        
        {
          title: this.$t("alarm.a23"),          
          dataIndex: "fmcAlarmHistoryType",
          customRender: (text, record, index) => {
            if (record.fmcAlarmHistoryType == null) {
              return "--";
            } else {
              return record.fmcAlarmHistoryType;
            }
          },
          width: 140,
          ellipsis: true,
        },
        {
          title: this.$t("alarm.a57"),//'警报等级',          
          dataIndex: "alarmPriorityText",
          customRender: (text, record, index) => {
            if (record.alarmPriorityText == null) {
              return "--";
            } else {
              return record.alarmPriorityText;
            }
          },
          width: 120,
          ellipsis: true,
        },
        {
          title: this.$t("alarm.a28"),          
          dataIndex: "eventMessage",
          customRender: (text, record, index) => {
            if (record.eventMessage == null) {
              return "--";
            } else {
              return record.eventMessage;
            }
          },
          width: 120,
          ellipsis: true,
        },
        {
          title: this.$t("alarm.a29"),          
          dataIndex: "correspondenceStatus",
          customRender: (text, record, index) => {
            if (record.correspondenceStatus == null) {
              return "--";
            } else {
              return record.correspondenceStatus;
            }
          },
          width: 100,
          ellipsis: true,
        },
        {
          title: this.$t("energy.enedata375"),          
          dataIndex: "acknowledgementStatus",
          customRender: (text, record, index) => {
            if (record.acknowledgementStatus == null) {
              return "--";
            } else {
              return record.acknowledgementStatus;
            }
          },
          width: 100,
          ellipsis: true,
        },
        {
          title: this.$t("alarm.a24"),          
          dataIndex: "siteName",
          customRender: (text, record, index) => {
            if (record.siteName == null) {
              return "--";
            } else {
              return record.siteName;
            }
          },
          width: 140,
          ellipsis: true,
        },
        {
          title: this.$t("alarm.a25"),          
          dataIndex: "equipmentName1",
          customRender: (text, record, index) => {
            if (record.equipmentName1 == null) {
              return "--";
            } else {
              return record.equipmentName1;
            }
          },
          width: 120,
          ellipsis: true,
        },
        {
          title: this.$t("alarm.a26"),          
          dataIndex: "equipmentName",
          customRender: (text, record, index) => {
            if (record.equipmentName == null) {
              return "--";
            } else {
              return record.equipmentName;
            }
          },
          width: 60,
          ellipsis: true,
        },
        {
          title: this.$t("alarm.a27"),          
          dataIndex: "equipmentName2",
          customRender: (text, record, index) => {
            if (record.equipmentName2 == null) {
              return "--";
            } else {
              return record.equipmentName2;
            }
          },
          width: 170,
          ellipsis: true,
        },
        // {
        //   title: this.$t("alarm.a52"),//'KPI类型',          
        //   dataIndex: "eventType",
        //   customRender: (text, record, index) => {
        //     if (record.eventType == null) {
        //       return "--";
        //     } else {
        //       return record.eventType;
        //     }
        //   },
        //   width: 100,
        //   ellipsis: true,
        // },
        // {
        //   title: this.$t("alarm.a53"),//'当前值',          
        //   dataIndex: "eventValue",
        //   customRender: (text, record, index) => {
        //     if (record.eventValue == null) {
        //       return "--";
        //     } else {
        //       return record.eventValue;
        //     }
        //   },
        //   width: 100,
        //   align:'right',
        //   ellipsis: false,
        // },
        // {
        //   title: this.$t("alarm.a54"),//'限值',          
        //   dataIndex: "exceededLimited",
        //   customRender: (text, record, index) => {
        //     if (record.exceededLimited == null) {
        //       return "--";
        //     } else {
        //       return record.exceededLimited;
        //     }
        //   },
        //   width: 100,
        //   align:'right',
        //   ellipsis: false,
        // },
        // {
        //   title: this.$t("alarm.a55"),//'死区',          
        //   dataIndex: "deadband",
        //   customRender: (text, record, index) => {
        //     if (record.deadband == null) {
        //       return "--";
        //     } else {
        //       return record.deadband;
        //     }
        //   },
        //   width: 100,
        //   align:'right',
        //   ellipsis: false,
        // },
        // {
        //   title: this.$t("alarm.a56"),//'占比（%）',          
        //   dataIndex: "rate",
        //   customRender: (text, record, index) => {
        //     if (record.rate == null) {
        //       return "--";
        //     } else {
        //       return record.rate;
        //     }
        //   },
        //   width: 100,
        //   align:'right',
        //   ellipsis: false,
        // },
        {
          title: this.$t("alarm.a33"),          
          dataIndex: "description",
          customRender: (text, record, index) => {
            if (record.description == null) {
              return "--";
            } else {
              return record.description;
            }
          },
          width: 140,
          ellipsis: true,
        },
        {
          title: this.$t("alarm.a34"),
          dataIndex: "chaozuo",
          scopedSlots: { customRender: "chaozuo" },
          width: 100,
          fixed: "right",
        },
      ],
      serviceType: parseInt(localStorage.getItem('serviceType')),
    };
  },
  // 获取警报页楼层/租户选择列表
  created() {
    getTenantList(this.$route.query.id)
      .then((res) => {
        let { data } = res;
        // console.log(data);
        for (let i = data.length - 1; i > -1; i--) {
          this.options.push({ value: data[i].id, name: data[i].name });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  },
  activated() {
    console.log("siteType>>>>", this.$store.getters.siteType);
    let siteType = this.$store.getters.siteType;
    // if(siteType==='WATER' || siteType==='ELECTRICITY'){
    //this.serviceType = 2;

    // }
  },
  mounted() {
    this.tableSize();
    window.addEventListener("resize", this.tableSize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.tableSize);
  },
  computed: {
    // 判断需要查询的条件
    conditionNo: function () {
      if (this.form.name == "") {
        return 0;
      } else {
        return 2;
      }
    },
  },
  methods: {
    moment,
    // 表格自适应方法
    tableSize() {
      let height = this.$refs.main.clientHeight;
      let tableHeight = height - 160;
      this.size.y = tableHeight;
    },
    showContentsChange(e) {
      this.notificationStatus = "";
      this.correspondenceStatus = "";
      if (e == "1") {
        this.notificationStatus = e;
      } else if (e == "2") {
        this.notificationStatus = e;
        this.correspondenceStatus = "0";
      } else if (e == "3") {
        this.notificationStatus = e;
      } else {
        this.notificationStatus = "";
      }
    },
     // 表格CSV导出
    csvExport() {
      const date1 = this.form.date1.format("YYYYMMDD");
      const date2 = this.form.date2.format("YYYYMMDD");
      const name =this.$t("alarm.a7");
      const fileName = `${date1}~${date2}${name}`;  
      //转换列表数据对象   
      csvDownload(this.data, this.columns, fileName);
    },

    // 条件查询方法(分页)
    inquirePage(page){
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.loading = true;
          let param = {
            siteId: parseInt(this.$route.query.id), 
            serviceType: this.serviceType,
            hisType: 1,            
            startDateTime: this.form.date1.format("YYYY-MM-DD HH:mm:ss"),
            endDateTime: this.form.date2.format("YYYY-MM-DD HH:mm:ss"),            
            pageNum: page,
            pageSize: this.pagination.pageSize, 
            alarmStatus:parseInt(this.showContents),                       
            equipmentName1:this.form.name,
          };
          console.log("getNotificationPageList param", param);
          getNotificationPageList(param)
            .then((res) => {              
              this.loading = false;
              let { data } = res.data;
              console.log("getNotificationPageList res", res);  
              this.pagination.total = res.data.total;     
              this.data = res.data.list;
            })
            .catch((err) => {
              this.loading = false;
              console.log(err);
            });
        } else {
          return false;
        }
      });
    },

    // 条件查询方法
    inquire() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.loading = true;
          let param = {
            action: 1,
            clientId: this.$store.getters.clientId,
            sitegrId: this.$store.getters.sitegrId,
            siteId: this.$route.query.id,
            notificationStatus: this.notificationStatus, //this.showContents,
            deviceName: this.form.name,
            tenantId: 0, //this.options[this.form.floor].value
            star: this.form.date1.format("YYYY-MM-DD HH:mm:ss"),
            end: this.form.date2.format("YYYY-MM-DD HH:mm:ss"),
            signalName: "",
            correspondenceStatus: this.correspondenceStatus, //alarmStatus
            serviceType: this.serviceType,
          };
          // console.log(data);
          console.log("GetEnergyNotificationList param", param);
          getEnergyNotificationList(param)
            .then((res) => {
              console.log("GetEnergyNotificationList result", res);
              // console.log("data", data);
              this.loading = false;
              let { data } = res.data;
              this.data = data;
            })
            .catch((err) => {
              this.loading = false;
              console.log(err);
            });
        } else {
          return false;
        }
      });
    },
    // 警报确认
    acknowledgement(value) {
      this.$confirm({
        title: this.$t("alarm.a47"),
        centered: true,
        onOk: () => {
          this.loading = true;
          const data = {
            params: value.param,
          };
          // console.log(data);
          acknowledgement(data)
            .then((res) => {
              // console.log(res);
              this.loading = false;
              if (res.errorCode == "00") {
                this.$message.success(res.msg);
                this.inquirePage(1);
              } else if (res.errorCode == "02") {
                this.$message.error(res.msg);
              }
            })
            .catch((err) => {
              console.log(err);
              this.loading = false;
            });
            
        },
      });
    },
    // 手动恢复
    manualRecovery(value) {
      this.$confirm({
        title: this.$t("alarm.a48"),
        centered: true,
        onOk: () => {
          this.loading = true;
          const data = {
            params: value.param,
          };
          // console.log(data);
          manualRecovery(data)
            .then((res) => {
              this.loading = false;
              // console.log(res);
              if (res.errorCode == "00") {
                this.$message.success(res.msg);
                this.inquirePage(1);
              } else if (res.errorCode == "02") {
                this.$message.error(res.msg);
              }
            })
            .catch((err) => {
              this.loading = false;
              console.log(err);
            });
        },
      });
    },
    // 应对（处理）按钮
    dispose(value) {
      this.dispose_visible = true;
      //this.correspondenceStatus = value.correspondenceStatus;
      this.params = value.param;
    },
    // 应对（处理）的确定按钮
    disposeOk() {
      this.processingLoading = true;
      const data = {
        params: this.params,
        happoKind: this.processingStatus,
      };
      // console.log(data);
      processing(data)
        .then((res) => {
          this.processingLoading = false;
          // console.log(res);
          if (res.errorCode == "00") {
            this.$message.success(res.msg);
            this.dispose_visible = false;
            this.inquirePage(1);
          } else if (res.errorCode == "02") {
            this.$message.error(res.msg);
          }
        })
        .catch((err) => {
          this.processingLoading = false;
          console.log(err);
        });
    },
    // 应对（处理）的取消按钮
    disposeCancel() {
      this.dispose_visible = false;
    },
    // 打开添加注释的对话框并且获取对应ID值
    annotation(key) {
      this.visible = true;
      this.params = key.param;
      this.value = key.description;
      setTimeout(() => {
        this.$refs.text.focus();
      }, 5);
      // console.log(key);
    },
    // 添加注释对话框的确定按钮
    annotationOk() {
      this.but_disabled = true;
      this.annotationLoading = true;
      let data = {
        params: this.params,
        comment: this.comment,
      };
      addComment(data)
        .then((res) => {
          // console.log(res);
          this.but_disabled = false;
          this.annotationLoading = false;
          if (res.errorCode == "00") {
            const newData = [...this.data];
            const target = newData.filter(
              (item) => this.params === item.param
            )[0];
            if (target) {
              target["description"] = this.comment;
            }

            this.visible = false;
            this.comment = "";
            this.$message.success(res.msg);
          } else if (res.errorCode == "02") {
            this.$message.error(res.msg);
          }
        })
        .catch((err) => {
          this.but_disabled = false;
          this.annotationLoading = false;
          console.log(err);
        });
    },
    // 添加注释对话框的取消按钮
    annotationCancel(e) {
      this.visible = false;
      this.comment = "";
    },
     // 初始日期/时间选择变化回调
    handleCalendarChange(value, mode) {
      const _val = JSON.stringify(value);
      const val = JSON.parse(_val);
      // console.log(moment(val).endOf("day"));
      this.form.date2 = moment(val).endOf("day");
    },
    // 开始日期限制选择范围（未来日期不可选）
    disabledStartDate(startValue) {
      // 获取计算机当前日期
      const nowValue = moment();
      if (!startValue || !nowValue) {
        return false;
      }
      // 禁用大于计算机日期的日期值
      return startValue.valueOf() > nowValue.valueOf();
    },
    // 终止日期/时间的日期不可选范围
    disabledEndDate(endValue) {
      const startValue = this.form.date1;
      const _value = JSON.stringify(startValue);
      const value = JSON.parse(_value);
      const nowValue = moment().endOf("day");
      if (!endValue || !startValue) {
        return false;
      }
      return (
        startValue.valueOf() > endValue.valueOf() ||
        endValue.valueOf() > nowValue.valueOf() ||
        moment(value).add(1, "months").valueOf() <= endValue.valueOf()
      );
    },
    // 表格字体恢复时间改变
    fontClass(record, index) {
      // if (record.normalTime == null) {
      //   return "red";
      // } else {
      //   return "";
      // }
      //modified by li_yj on 2022-9-21 绿色：已处理；红色：严重警报；黑色：普通；
      // console.log("fontClass record:",record);
      if(record.normalTime&&record.normalTime!="-"){
        return "green";
      }else if(record.alterType==1){
        return "red";
      }else{
        return "";
      }
    },
    // 手动恢复按钮根据条件隐藏
    showManual(value) {
      let tmp = value.param.split(",");
      if (tmp[9] == "51" || tmp[9] == "13" || tmp[9] == "181") {
        if (tmp[11] == "9") {
          return true;
        }
      } else {
        return false;
      }
    },
  },
  components: {
    "my-tabletitle": tabletitle,
    "my-headertitle": headerTitle,
  },
};
</script>

<style scoped>
div /deep/.ant-modal-header {
  background-color: #7682ce;
}
#alarm {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.header {
  min-width: 850px;
  padding: 0 20px;
  overflow: hidden;
}
.main {
  height: calc(100% - 140px);
  width: 100%;
  min-width: 670px;
  padding: 0px 20px;
  overflow: hidden;
}
.form_row {
  display: flex;
  align-items: flex-end;
}
.form_col {
  margin-right: 15px;
  min-width: 1px;
}
.fool {
  width: 100px;
  height: 10px;
}
.table_but {
  margin: 5px 0px;
}
div /deep/.red {
  color: red;
}
div /deep/ .green{
  color:green;
}
</style>